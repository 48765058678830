.HeaderCont {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-inline: auto;
  width: 75%;
}

.BrandLogo {
  width: 127px;
  height: 110px;
}

.BrandName {
  font-family: "Libre Baskerville", serif;
  text-shadow: #ffffff 3px 3px 0px, rgba(0, 0, 0, 0.2) 6px 6px 0px;
  cursor: pointer;
  font-size: 55px;
}

.SocialIcon {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 10px 0;

  svg {
    font-size: 20px;
    color: black;
  }
}

.BookBtn {
  border: none;
  border-radius: 25px;
  background-color: black;
  padding: 8px 15px;
  font-weight: 600;
  color: white;
  font-size: 10px;
  box-shadow: var(--shd, 0 1px 4px rgba(0, 0, 0, 0.6));
  transition: background-color 0.4s ease;
  cursor: pointer;
}

.BookBtn:hover {
  background-color: #7AD3C3;
  color: black;
}

.HrLine {
  width: 60%;
  border: none;
  height: 1px;
  background-color: black;
}

.MoreDropdownContainer {
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
}

.MoreText {
  font-weight: 600;
  color: black;
  padding: 5px;
  position: relative;
  width: fit-content;
}

.DropdownMenu {
  position: absolute;
  left: 50%;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 5px;
  z-index: 10;
  width: fit-content;
  transform: translateX(-50%);
}

.DropdownItem {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: black;
  font-size: 14px;
  border-bottom: 1px solid black;
}

.DropdownItem:hover {
  background-color: #f0f0f0;
  color: #D3A75A;
}

.HrDesktop {
  display: block;
}

.HrMob {
  display: none;
}

/* Hamburger Icon for Mobile */
.HamburgerIcon {
  display: none;
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 24px;
  cursor: pointer;
  z-index: 20;
}
.MobileMenuLinks,.DropdownMenu{font-family: "Overpass";}
/* Mobile Menu Overlay */
.MobileMenuOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:#181818;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  z-index: 30;
}

.CloseIcon {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 24px;
  color: white;
  cursor: pointer;
  z-index: 40;
}

.MobileMenuLinks {
  text-align: center;
}

.MobileMenuLinks .DropdownItemMob {
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding: 15px 0;
  display: block;
  border: none;
}

.MobileMenuLinks .DropdownItemMob:hover {
  color: #D3A75A;
  background-color: transparent;
}

@media (max-width: 767px) {
  .HeaderCont {
    flex-direction: column;
  }

  .BrandName {
    font-size: 40px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .HrDesktop {
    display: none;
  }

  .HrMob {
    display: block;
    width: 100%;
  }

  .SocialIcon {
    gap: 30px;
    margin: 10px 0;

    svg {
      font-size: 30px;
    }
  }

  .BookBtn {
    padding: 15px 40px;
    font-size: 14px;
    margin: 0 auto 15px 0;
  }

  .MoreDropdownContainer {
    display: none; 
  }

  .HamburgerIcon {
    display: block; 
  }
}
