.ContactCont {
  background-color: #F0D3B1;
  padding: 45px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-family: "Raleway", sans-serif;
    font-size: 21px;
    font-weight: bold;
    margin-top: 0;
  }
}

.RoyalImg {
  width: 77px;
  height: 23px;
  vertical-align: middle;
}

.CallBook {
  display: flex;
  gap: 20px;
  padding: 10px 0;

  span {
    background-color: black;
    padding: 10px;
    border-radius: 50%;
  }

  svg {
    color: #F0D3B1;
    vertical-align: middle;
    font-size: 25px;
  }

}

.BookNowBtn {
  border: none;
  border-radius: 50px;
  background-color: black;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 18px;
  color: #F0D3B1;
  box-shadow: var(--shd, 0 1px 4px rgba(0, 0, 0, 0.6));
  transition: background-color 0.4s ease;
  cursor: pointer;
}

.Appoint {
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.Address {
  font-family: "Raleway", sans-serif;
  font-size: 19px;
  font-weight: 600;
  margin-top: 0;
}

.TimingNMap {
  h2 {
    font-family: "Libre Baskerville", serif;
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: left;
  }

}

.WeeeklyHours {
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-size: 18px;
    font-family: "Raleway", sans-serif;
  }

  p {
    margin: 0;
    font-weight: 500;
  }
}

.GoogleMap {
  width: 40%;
}

.TimingNMap {
  display: flex;
  justify-content: space-evenly;
  width: 70%;
}

.CopyRight {
  width: 100%;
  background: black;
  padding: 15px 0;
  color: white;
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  text-align: center;

}

@media (max-width: 767px) {
  .ContactCont {
    padding: 30px 0;

    h4 {
      font-size: 18px;
      line-height: 2.5em;
      margin-bottom: 15px;
    }
  }

  .CallBook {
    width: 100%;
    justify-content: center;

    span {
      padding: 15px;
    }

    svg {
      font-size: 25px;
    }

  }

  .BookNowBtn {
    padding: 15px 40px;
  }

  .Appoint {
    font-size: 14px;
  }

  .TimingNMap {
    flex-direction: column;
    width: 85%;
    gap: 30px;

    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .WeeeklyHours {
    span {
      font-size: 20px;
    }

    p {
      margin: 0;
      font-weight: 500;
    }
  }
  .GoogleMap {
    width: 100%;
  }
}