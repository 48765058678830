.ReviewUsCont {
  width: 100%;
  background-color: #EBC691;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;

  h2 {
    font-family: "Libre Baskerville", serif;
    font-size: 35px;
    font-weight: 500;
    margin-top: 0;
  }
}

.ReviewSocialIcon {
  display: flex;
  gap: 20px;

  span {
    background-color: black;
    padding: 10px;
    border-radius: 50%;
  }

  svg {
    color: #EBC691;
    vertical-align: middle;
    font-size: 30px;
  }

}
@media (max-width: 767px) {
  .ReviewUsCont {
    padding: 30px 0;
    h2 {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
  .ReviewSocialIcon {
    span {
      padding: 8px;
    }
  
  }
}
